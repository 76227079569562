.btn {
  display: block;
  margin: 0 auto;
  cursor: hand;

  &.cta{
    transition: transform .2s ease-in-out;

    &:hover{
      transform: scale(1.1);

    }
  }

  &.start {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    font-family: $font-bold;
    font-size: $fontSize16;
    text-align: center;
    line-height: 55px;
    transform: translate3d(0, 0, 0);
    transition: transform .2s ease-in-out;

    &[disabled] {
      transform: translate3d(0, 0, 0) rotateY(90deg);
    }

    &::before {
      position: absolute;
      opacity: 0;
      width: 55px;
      height: 55px;
      border: solid 1px #fff;
      border-radius: 150px;
      content: ' ';
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      top: 50%;
      transition: width .2s ease-in-out, height .2s ease-in-out;
    }

    &.ready {
      background-color: #fff;
      color: #009CDE;
      font-size: $fontSize20;

      &:hover {
        transform: scale(1.2);
      }

      &::before {
        opacity: 1;
        width: 66px;
        height: 66px;
      }

      span {
        top: 1px;
        position: absolute;
        left: 16px;
      }
    }

    svg {
      width: 56px;
      height: 51px;
    }

    span {
      top: 1px;
      position: absolute;
      left: 14px;
    }

  }

  &.question {
    position: relative;
    max-width: 573px;
    width: 100%;
    height: 60px;
    background-color: $color-white;
    color: $color-black;
    border-radius: 61px;
    text-align: center;
    line-height: 1;
    padding: 11px 0 0 0;
    margin-bottom: 15px;
    z-index: 600;

    span {
      width: 80%;
      display: inline-block;
      vertical-align: middle;
    }
  }
}


.btn {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.btn--white {
  @extend .btn;
  color: #009CDE;
  font-family: Miso-Bold;
  width: 100%;

  margin: 20px auto;
  line-height: 70px;
  height: 70px;
  max-width: 199px;
  text-align: center;
  font-size: 2.1rem;

  &.cta {
    background: url('/dist/img/btn.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  @media screen and(min-width: 1600px) {
    font-size: 2.5rem;
  }

}

.btn--white--large {
  @extend .btn--white;
  background: url('/dist/img/btn-large.svg');
  max-width: 350px;
}

.btn__replay, .btn__replay img{
  height:21px;
  width:21px;
  max-height:21px;
  max-width:21px;
}