.section.result {
  background-color: #009CD8;

  .result__score {
    background-image: url("/dist/img/score.svg");
    background-repeat: no-repeat;
    text-align: center;
    line-height: 107px;
    width: 116px;
    height: 107px;
    display: inline-block;
    vertical-align: top;
    margin: 80px 0 0 30px;
    font-size: $fontSize40;
    color: #32373C;
  }


  .btn--white--large{
    //transform: scale(1.1);
  }
  .btn__replay {
    @extend .btn--white;
  }

  .result__text {
    width: calc(100% - 151px);
    padding: 80px 25px 85px 25px;
    display: inline-block;
  }

  .result__score-text {
    max-width: 91%;
    display: none;
    &.active {
      display: block;
    }
  }

  .slide {
    background-image: url(/dist/img/stars_large.svg);
  }

  .slide2 {
    background-color: #00628b;
    background-image: none;
  }

  .slide3 {
    .slide__content {
      display: flex;
      flex-flow: column;
      justify-content: center;

      .content__wrapper {
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      h1 {
        font-size: 4rem;
        margin-bottom: 50px;
        @media screen and (min-width: 1600px){
          font-size: 6rem;
        }
      }
      p {
        margin-bottom: 110px;
        font-size: 3rem;
        @media screen and (min-width: 1600px){
          font-size: 4rem;
        }
      }
    }
  }

  .result__box {
    width: 100%;
    max-width: 690px;
    margin: 80px auto 0 auto;
    padding: 3px 0;
    position: relative;
    .result-box__top {
      position: absolute;
      width: 100%;
    }
    .result-box__bottom {
      position: relative;
      width: 100%;
      //bottom: 0px;
      transform: translate3d(0, -5px, 0);
    }
    img {
      width: 100%;
    }

    .result__box-inner {
      width: 100%;
      text-align: left;
      margin-top: 5px;
      margin-left: auto;
      margin-right: auto;
      background-color: #fafafa;
      color: #32373C;
      max-width: calc(100% - 40px);
      overflow: hidden;

      h2 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: $font-bold;
        @media screen and(min-width: 1600px) {
          font-size: 5rem;
          line-height: 5rem;
        }
      }
      p,
      h2 {
        color: #32373C;
      }

      p {
        font-size: $fontSize20;
        font-family: $font-regular;

        @media screen and(min-width: 1600px) {
          font-size: 2.5rem;
        }
      }
    }
  }

  .result__footer {
    width: 460px;
    margin-left: auto;
    margin-right: auto;

    text-align: left;

    .footer__info {
      max-width: calc(100% - 114px);
      width: 100%;
      display: inline-block;
    }

    p {
      text-align: left;
      font-size: $fontSize16;
    }

    strong {
      font-size: $fontSize20;
      font-family: $font-bold;
    }

    a {
      font-family: $font-bold;
      margin-top: 10px;
    }
  }

  .share__wrapper {
    margin-top: 10px;
    width: 100%;
    height: 25px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .btn__replay{
      line-height: 2.1rem;
      text-align: left;
      margin: 20px 0;
      img{
        position: relative;
        top: 3px;
      }
    }
    .share__left {
      width: 49%;
    }
    .share__right {
      width: 49%;
      display: flex;
      flex-flow: row;
      margin-top :25px;
      justify-content: flex-end;
      margin-right: 50px;
    }
    a {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.active {
    .result__box-inner {
      animation-name: openResultCanvas;
      animation-duration: 1s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
    }
  }



  .result-box-bottom{
    margin-left: 45px;
    display:flex;
    flex-flow:row;
    justify-content: space-around;
    &__half{
      &:first-of-type{
        padding-top: 20px;
      }
      width: 100%;
      max-width: 50%;
    }
  }
}

@media screen and (min-width: 1600px) {
  //.section {
  //  &.section1 {
  //    .answer__wrapper {
  //      .question {
  //        &:first-child {
  //          height: 75px;
  //          font-size: 2.5rem;
  //        }
  //      }
  //    }
  //  }
  //}



  .hint {
    .content {
      p {
        font-size: 2.5rem;
      }
    }
  }


  .cta {
    &.btn--white--large {
      background-size: 100% 100%;
      background-position: 0px 0px;
      font-size: 2.2rem;
      transform: scale(1);
    }
  }

  .section {
    &.result {
      .btn__replay {
        margin: 0 auto;
      }
    }
  }

  @keyframes openResultCanvas {
    0% {
      max-height: 10px;
    }

    100% {
      max-height: 550px;
    }
  }
}

@media screen and (max-width: 1600px) {

  @keyframes openResultCanvas {
    0% {
      max-height: 10px;
    }

    100% {
      max-height: 420px;
    }
  }
}

