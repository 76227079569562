.stars{
  position: absolute;
  transform: translate3d(0, 0, 0) scale(0.8);
  @include popIn(translate3d(0, 0, 0), 0.8, 1, 5s);
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
  }
}

.stars--1{
  @extend .stars;
  max-width: 30px;
  max-height: 50px;
  left: 87px;
  top: 47px;
  animation-delay: .2s;
  animation-duration: 5s;
}
.stars--2{
  @extend .stars;
  max-width: 61px;
  max-height: 71px;
  left: 61px;
  bottom: 61px;
  animation-delay: .7s;
  animation-duration: 4s;

}
.stars--3{
  @extend .stars;
  max-width: 45px;
  max-height: 99px;
  right: 51px;
  top: 33px;
  animation-delay: .2s;
  animation-duration: 7s;
}
.stars--4{
  @extend .stars;
  max-width: 69px;
  max-height: 40px;
  right: 69px;
  bottom: 47px;
  animation-delay: .5s;
  animation-duration: 8s;
}