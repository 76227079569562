body {
  background: #009cd8;
}

#fragmentHider {
  transition: opacity .2s ease-in;
}

.warning {
  display: none;
  img {
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    top: 50%;
    left: 50%;
    margin: 0 auto;
  }

}

audio {
  visibility: hidden;
}

svg {
  position: absolute;
  top: 2px;
  left: 0;
}

/*********************************************************************
	 COUNTDOWN
*********************************************************************/

.countdown {
  position: relative;
  margin: auto;
  height: 45px;
  width: 45px;
  text-align: center;
  transform: translate3d(0, 0, 0);
}

.countdown-number {
  color: white;
  display: inline-block;
  line-height: 46px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.svg-countdown {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);

  circle {
    stroke-dasharray: 124px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: #fff;
    fill: #32373C;
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 124px;
  }
}

/*********************************************************************
	HEADER
*********************************************************************/

.header {
  position: fixed;
  top: 0;
  //left: 0;
  padding: 25px 120px;
  left: 50%;
  transform: translateX(-50%);
}

.header__logo {
  width: 110px;
  height: 40px;
}

.meta__navigation {
  max-width: 228px;
  width: 100%;
  position: absolute;
  right: 45px;
  top: 25px;
  z-index: 300;
  transform: translate3d(0, -200px, 0);
  transition: transform .2s ease-in-out;

  &.show {
    transform: translate3d(0, 0, 0);
    transition-delay: .8s;
  }

  .header__counter,
  .header__hint,
  .header__score,
  .header__time {
    display: inline-block;
    width: 45px;
    height: 45px;
    position: relative;
    vertical-align: top;
    margin-right: 18px;
    transform: translate3d(0, 0, 0);
    z-index: 300;

    svg {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 0;
    }
  }

  .header__hint {
    margin-right: 0;
    cursor: pointer;
    background-image: url("/dist/img/icon_hint.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: none;

    &.show {
      display: inline-block;
    }

    svg {
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
  }

  .header__score-wrapper {
    position: absolute;
    width: 45px;
    height: 45px;
    display: inline;
    .header__score {
      background-image: url("/dist/img/icon_score.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      span {
        width: 100%;
        height: 100%;
        position: absolute;
        color: #fff;
        z-index: 1;
        text-align: center;
        line-height: 45px;
        padding-right: 4px;
      }
    }
  }

  .header__counter {
    background-color: #32373C;
    border-radius: 100%;

    .counter__inner {
      text-align: center;
    }

    span {
      color: $color-white;
      font-size: $fontSize16;
      line-height: 45px;
    }
  }
}

/*********************************************************************
	HINT
*********************************************************************/
.hint__backdrop.show {
  content: '';
  width: 100%;
  position: absolute;
  background: rgba(20, 20, 20, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 800;
}

.hint {
  width: 100%;
  height: 0vh;
  padding-top: 0px;
  top: -95px;
  position: absolute;
  opacity: 0;
  transition: all .8s ease-in;
  background-color: #fff;
  color: #333;
  z-index: 900;
  border-bottom: solid 20px #4A5054;

  .content {
    margin: 10px auto;
    max-width: 50vw;
    height: 100%;
    position: relative;
    //    opacity : 0;
    z-index: 0;
    overflow: hidden;

    //transition: opacity .2s ease-in-out;
  }

  &:before {
    content: '';
    position: absolute;
    transform: translate3d(-50%, 100%, 0);
    left: 50%;
    bottom: -20px;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 75px;
    z-index: 500;

    background-image: url(/dist/img/hint_drawer.svg);
    background-repeat: no-repeat;
    background-position: center top;
  }

  &.show {
    padding-top: 150px;
    height: 80vh;
    top: 0px;

    .close {
      opacity: 1;
      transition: opacity .2s ease-in-out;
      transition-delay: .8s;
    }

    .content {
      opacity: 1;
      //z-index: 1000;
    }

  }
  .close {
    position: absolute;
    top: 100px;
    right: 100px;
    width: 100%;
    height: 100%;
    max-width: 38px;
    max-height: 38px;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
    }

    &::before {
      position: absolute;
      width: 38px;
      height: 38px;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      top: 50%;
      transition: width .2s ease-in-out, height .2s ease-in-out;
    }
  }
}

/*********************************************************************
	 TIME DISTURBER
*********************************************************************/

.time__disturber {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -15;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #028cc7;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  display: none;
  transition: all .2s ease-in-out;

  &.active {
    display: block;
    z-index: 15;
    opacity: 1;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

#svgSj6f1gmacd7 {
  left: 50%;
  transform: translateX(-50%);
  background-color: #009CD8;
}

/*********************************************************************
	 BTN
*********************************************************************/

/*********************************************************************
	 SLIDES
*********************************************************************/
.answer {
  position: fixed;
  top: 50px;
  left: 0;
}

.slide__content {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.slide, .section {
  -ms-transform-style: preserve-3d;
  -ms-backface-visibility: visible;
}

#fullpage,
.header {
  //max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.section {
  text-align: center;
  color: $color-white;
  overflow: hidden;

  .question__wrapper {
    position: absolute;
    background-color: #333;
    top: 0;
    width: 100%;
    height: 50%;
    //padding-top: 195px;

    p {
      font-size: 2.8rem;
      font-family: Miso-Bold;
    }

    .question__wrapper-inner {
      max-width: 570px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      height: 100%;
      justify-content: center;

      @media screen and(min-width: 1600px) {
        max-width: 750px;
      }

      p {
        margin-top: 100px;
      }
    }
  }

  .answer__wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    //padding-top: calc((50vh - 288px) / 2);

    .btn__wrapper {
      display: flex;
      flex-flow: column;
      justify-content: center;
      height: 100%;

      .btn {
        @media screen and(min-width: 1600px) {
          font-size: 3rem;
          max-width: 860px;
          height: 85px;
        }

        max-width: 570px;
        height: 61px;
      }
    }
  }
  .answer__wrapper--nobackground {
    @extend .answer__wrapper;
    z-index: 10000;
  }

  &.active {
    .hint {
      opacity: 1;
    }
  }

  &.intro {
    .header-text {
      margin-top: 100px;
      max-width: 350px;

      h1 {
        font-size: 3.2rem;
        line-height: 34px;
      }

      p {
        margin-top: 0px;
        font-size: 2rem;
      }
    }
    .question__wrapper {
      padding-top: 0;
      height: 48%;
      background-color: #009CD8;
      text-align: left;

      p {
        transform: translate3d(0, 0, 0);
      }

      .intro__description {
        max-width: 569px;
        text-align: center;
      }

      .question__wrapper-inner {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        padding-bottom: 20px;
      }
    }

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 2rem;

    }

    .btn__wrapper {
      justify-content: center;
    }
    .intro__description-wrapper {
      margin: 0 auto 0 auto;
      max-width: 660px;
      width: 100%;

      h2 {
        margin-top: 0px;
        margin-bottom: 30px;
      }
    }

    .answer__wrapper {
      background-color: #028CC7;
      padding-top: 0;
      height: 52%;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .description__row {
      display: flex;
      flex-flow: row;
      width: 100%;
    }

    .btn__wrapper {
      .btn {
        font-size: 2rem;
        height: 54px;
        width: 54px;
      }
    }

    .description__box {
      &:nth-child(even) {
        margin-right: 0px;
      }
      width: 49%;
      display: flex;
      margin-bottom: 25px;
      margin-left: 30px;
      margin-right: 20px;

      .description__text {
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: center;
        margin-left: 10px;
        text-align: left;
        max-width: 220px;
      }
      .box__image {
        width: 50px;
        height: 50px;
      }
      .box__image,
      p {
        display: inline-block;
        vertical-align: top;
      }

      p {
        width: calc(100% - 55px);
        padding: 0 25px;
        text-align: left;
        font-size: $fontSize14;
        line-height: 1.2;
      }
    }

    h1 {
      margin-bottom: 15px;
    }
  }

  &.section1 {
    .question__wrapper {
      background-color: #A2BD5F;
    }

    .ripple-effect {
      background-color: #A2BD5F;
    }
    .answer__wrapper {
      background: #82AC60;
      z-index: 200;

      .question {
        &:first-child {
          padding-top: 22px;
        }

        &:nth-child(2) {
          span {
            width: 93%;
          }
        }
      }
    }
  }

  &.section2 {
    .question__wrapper {
      background-color: #009CDE;
    }

    .ripple-effect {
      background-color: #009CDE;
    }
    .answer__wrapper {
      background: #BEE4E3;
      transform: translate3d(0, 0, 0);
      .question {
        &:last-child {
          span {
            width: 93%;
          }
        }
      }
    }
  }

  &.section3 {
    .question__wrapper {
      background-color: #86CDD2;
    }

    .ripple-effect {
      background-color: #86CDD2;
    }
    .answer__wrapper {
      background: #00A8C3;

      .question {
        &:first-child,
        &:last-child {
          span {
            width: 67%;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          span {
            width: 93%;
          }
        }
      }
    }
  }

  &.section4 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .fp-slidesContainer {

      .slide__inner {
        width: 100%;
        height: 100%;
      }

      .question__wrapper {
        text-align: center;

      }

      .fp-tableCell {
        position: relative;
        width: 100%;
      }

      .slide1 {

        .ripple-effect {
          background-color: #ed9700;
        }

        .question__wrapper {
          background-color: #ed9700;

        }

        .answer__wrapper {
          background-color: #D07C00;
        }
        .answer__wrapper--nobackground {
          background-color: transparent;
        }

        .question {
          &:first-child,
          &:nth-child(3) {
            padding-top: 22px;
          }
        }
      }

      .slide2 {
        .question__wrapper {
          background-color: #00A8C3;
        }

        .ripple-effect {
          background-color: #00A8C3;
        }
        .answer__wrapper {
          background-color: #86CDD2;
        }
        .btn:nth-child(2) {
          span {
            width: 90%;
          }
        }

      }

      .slide3 {
        .question__wrapper {
          background-color: #0F5758;
        }

        .ripple-effect {
          background-color: #0F5758;
        }
        .answer__wrapper {
          background-color: #008E94;

          .question {
            span {
              width: 85%;
            }
          }
        }

      }
    }
  }

  &.section6 {
    .question__wrapper {
      background-color: #ABC8A4;
      .question__wrapper-inner {
        max-width: 530px;
        @media screen and (min-width: 1600px){
          max-width: 750px;
        }
      }
    }

    .ripple-effect {
      background-color: #ABC8A4;
    }
    .answer__wrapper {
      background-color: #E1E6B9;
      z-index: 20000;
      transform: translate3d(0, 0, 0);
      .question {
        &:nth-child(2) {
          span {
            width: 86%;
          }
        }
      }
    }
  }

  &.section5 {
    &.lightning {
      .question__wrapper {
        p {
          color: $color-white;
        }
      }

      .ripple-effect {
        background-color: #1A2C54;
      }

      .answer__wrapper {
        .btn {
          &.question {
            background-color: $color-white;
            cursor: pointer;

            span {
              color: $color-black;
            }
          }
        }
      }
    }
    .question__wrapper {
      background-color: #0A152D;
      .question__wrapper-inner {
        max-width: 490px;
      }
      @media screen and(min-width: 1600px) {
        .question__wrapper-inner {
          max-width: 800px;
        }
      }

      p {
        color: #1A2C54;
      }
    }
    .answer__wrapper {
      background-color: #162545;
      z-index: unset;
      .btn {
        &.question {
          background-color: #0A152D;
          cursor: default;

          span {
            color: #32373C;
          }

          &:first-child {
            span {
              width: 52%;
            }
          }

          &:last-child {
            span {
              width: 47%;
            }
          }

          &:nth-child(2) {
            span {
              width: 66%;
            }
          }

          &:nth-child(3) {
            span {
              width: 95%;
            }
          }
        }
      }
    }
  }

  &.section7 {
    .question__wrapper {
      background-color: #82AC60;
      .question__wrapper-inner{
        max-width: 660px;
        @media screen and(min-width:1600px) {
          max-width: 850px;
        }
      }
    }

    .ripple-effect {
      background-color: #82AC60;
    }

    .answer__wrapper {
      background-color: #A2BD5F;
      .btn span {
        width: 93%;
      }
      .btn:nth-child(2) span {
        width: 70%;
      }
    }
  }

  &.section8 {
    .question__wrapper {
      background-color: #80C8F9;

      .question__wrapper-inner {

        max-width: 520px;
        margin-left: 90px;
        @media screen and(min-width: 1600px) {
          max-width: 700px;
          margin-left: 110px;
        }
      }
    }

    .ripple-effect {
      background-color: #80C8F9;
    }
    .answer__wrapper {
      background-color: #BDD5D6;

      .btn {
        margin-left: 40px;

        &.question {
          &:first-child,
          &:nth-child(3) {
            span {
              width: 64%;
            }
          }

          &:nth-child(2) {
            span {
              width: 96%;
            }
          }

          &:last-child {
            span {
              width: 77%;
            }
          }
        }
      }
    }
  }


}

.in-front-of-illustration {
  position: relative;
  z-index: 1000;
}

.content__wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  //max-height: 100%;
  top: 0;
  overflow: hidden;
}

.splitscreen-wrapper {
  display: flex;
  flex-flow: row;
  height: 100vh;
  justify-content: space-around;

  .half {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    max-width: 50vw;
  }
}


.section {
  .score, .signup, .thanks {
    background-color: #009CD8;
  }
}

#time__disturber {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: 100%;
  max-width: 80%;
  max-height: 80%;
  top: 50%;
  left: 50%;
}

.result_canvas__top {
  position: relative;
  top: 5px;
}

@keyframes rotateFull {
  0% {
    transform: translate3d(-50%, -50%, 0) rotateZ(0deg) scale(1.5);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotateZ(360deg) scale(1.5);
  }
}

.wiggle {
  -webkit-animation-name: wiggle;
  -moz-animation-name: wiggle;
  -o-animation-name: wiggle;
  animation-name: wiggle;
  animation-duration: .8s;
  animation-timing-function: ease-in;
}

@keyframes wiggle {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

a, img {
  outline: none;
  border: none;
}



@media screen and (min-width: 1600px) {
  .section.intro .header-text {
    max-width: initial;

    h1 {
      max-width: 380px;
    }

    p,
    h2 {
      font-size: 2.6rem;
      line-height: 1.2;
    }

    p {
      max-width: 450px;
    }

    h2 {
      max-width: 400px;
      letter-spacing: 1.5px;
    }
  }
}
