$d: 20px;
$total : 8;
$distance : 50;

.particle-system {
  position: relative;
  width: 60px;
  height: 60px;
  transform: translate3d(-50%, -50%, 0);
  left:50%;
  top: 50%;

  &.active {
    .particle {
      position: absolute;
      width: $d;
      height: $d;
      animation: shoot 3s ease-out;
      animation-fill-mode: forwards;
      animation-name: shoot, fade;

      @for $i from 0 to $total {
        $t: ((1 + .01*random(100))+0.5)*1s;

        &:nth-child(#{$i + 1}) {
          transform: translate((random($distance) - $distance/2 )*1px, (random($distance) - $distance/2 )*1px);

          left: 50%;
          top: 50%;
          //background: hsl(random(360), 100%, 65%);
          background: url('/dist/img/score.svg');
          background-size: contain;
          animation-duration: $t;
          animation-delay: -.01*random(100)*$t;
        }
      }
    }
  }

  @keyframes shoot {
    0% {
      transform: translate(-50%, -50%);
    }
  }
  @keyframes fade {
    60%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
}
