@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animate($name, $duration, $iterationCount:1, $fillMode:forwards, $direction:alternate) {
  animation-name: $name;
  animation-duration: $duration;

  @if $iterationCount {
    animation-iteration-count: $iterationCount
  }
;

  animation-timing-function: ease-in-out;
  animation-fill-mode: $fillMode;
  animation-direction: $direction;
}

@mixin slideInFromRight($from, $to, $duration, $iterationCount:1, $fillMode:forwards) {
  $name: unique-id();
  @include animate($name, $duration, $iterationCount, $fillMode);
  @include keyframes($name) {
    0% {
      right: $from;
    }
    100% {
      right: $to;
    }
  }
}

@mixin animateFromTo($from, $duration, $iterationCount:1, $fillMode:forwards) {
  $name: unique-id();
  @include animate($name, $duration, $iterationCount, $fillMode);
  @include keyframes($name) {
    100% {
      @content
    }
  }
}

@mixin slideUpAndDown($from, $to, $duration, $iterationCount:1, $fillMode:forwards) {
  $name: unique-id();
  @include animate($name, $duration, $iterationCount, $fillMode);
  @include keyframes($name) {
    0% {
      top: $from;
    }
    100% {
      top: $to;
    }
  }
}

@mixin animateTranslate($from, $to, $duration, $iterationCount:1, $fillMode:forwards) {
  $name: unique-id();
  @include animate($name, $duration, $iterationCount, $fillMode);
  @include keyframes($name) {
    0% {
      transform: $from;
    }
    100% {
      transform: $to;
    }
  }
}

@mixin popIn($translate, $from, $to, $duration, $iterationCount:infinite, $fillMode:forwards) {
  $name: unique-id();
  @include animate($name, $duration, $iterationCount, $fillMode);
  @include keyframes($name) {
    0% {
      transform: $translate scale($from);
    }
    100% {
      transform: $translate scale($to);
    }
  }
}