.result {
  .thanks {
    h1 {
      margin-bottom: 20px;
    }
    .btn-wrapper {
      margin-top: 50px;
    }
    .cta {
      height: 58px !important;
      max-height: 58px !important;
      line-height: 58px !important;
    }
  }
}