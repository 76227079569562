.signup {
  .signup-form {
    display:flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    justify-content: center;

    .form-error {
      margin: 10px 0px;
      color: #F77B55;
      display:none;
      &.active{
        display:block;
      }
    }

    h1{
      margin-bottom: 20px;
      font-size: 3rem;
      @media screen and(min-width: 1600px) {
        font-size: 5rem;
      }
    }

    p{
      @media screen and(min-width: 1600px) {
        font-size: 2.5rem;
      }
    }

    .input__wrapper{
      margin-top: 50px;
      margin-bottom: 50px;
    }
    input {
      border-radius: 0;
      font-family: Miso;
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: solid 1px #fff;
      font-size: 2rem;
      color: #fff;
      padding: 15px 5px;
      //text-transform: uppercase;

      margin-top: 10px;
      margin-bottom: 10px;
      &::-webkit-input-placeholder { /* Chrome */
        color: #fff;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #fff;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #fff;
      }
      &.error{
        border-bottom: solid 1px #ba0000;

      }

      @media screen and(min-width: 1600px) {
        font-size: 2.5rem;
      }
    }
  }

  .signup__image-wrapper {
    width: 100%;
    max-width: 325px;
    height: 100%;
    max-height: 387px;
    margin: 0 auto;
  }

  .signup-form__content-wrapper {
    //height: 387px;
    text-align: left;
    max-width: 450px;
    margin: 0 auto;
    padding: 0 10px;
    @media screen and(min-width: 1600px) {
      max-width: 600px;
    }
  }

  button{
    margin-top: 60px;
  }
}
