/*********************************************************************
	 ILLUSTRATION
*********************************************************************/
.illustration {
  z-index: 50;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;

  svg, img{
    width: 100%;
    height: 100%;
  }



}

.section {
  .illustration {
    visibility: hidden;
    display: none;
    opacity: 0;
    transition: opacity .2s;
    transition-delay: .2s;
  }
}

.section.active {
  .illustration {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.section .slide {
  .illustration {
    visibility: hidden;
    opacity: 0;
    display: none;

  }
}

.section .slide.active {
  .illustration {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}

.section {

  #intro_illustration1 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    bottom: 0px;
    right: 5%;

    width: 100%;
    height: 100%;
    max-width: 55%;
    max-height: 69%;
  }
  /*
      SECTION 1
   */
  #section1_illustration1 {
    position: absolute;
    transform: translate3d(0,10px,0);
    bottom: 0;
    left: 2%;
    max-width: 214px;
    max-height: 215px;
  }
  #section1_illustration3 {
    position: absolute;
    transform: translate3d(0,10px,0);
    bottom : 0;
    left: 2%;
    z-index:600;
    max-width: 214px;
    max-height: 30px;
  }
  #section1_illustration2 {
    position: absolute;
    transform: translate3d(0,10px,0);
    bottom: 0;
    right: 2%;
    max-width: 223px;
    max-height: 147px;
  }
  #section1_illustration4 {
    position: absolute;
    transform: translate3d(0,10px,0);
    bottom: 0;
    right: 2%;
    z-index:600;
    max-width: 223px;
    max-height: 30px;
  }

  /*
      SECTION 2
   */
  #section2_illustration1 {
    position: absolute;
    top: 100%;
    transform: translate3d(0,0,0)  rotateZ(0deg);
    right: 0;
    z-index: 0;
    max-width: 375px;
    max-height: 198px;
  }

  #section2_illustration2 {
    position: absolute;
    z-index: 0;
    bottom: 0;
    max-width: 179px;
    max-height: 205px;
  }

  /*
      SECTION 3
   */
  #section3_illustration1 { // speech bubble
    position: absolute;
    transform: translate3d(0, 0, 0) scale(0.7);
    bottom: 0;
    left: 44px;
    max-width: 196px;
    max-height: 196px;
  }

  #section3_illustration2 { // Girl
    position: absolute;
    transform: translate3d(0, 0, 0);
    bottom: 60px;
    right: 0;
    max-width: 40%;
    max-height: 110%;
  }
  #section3_illustration3 { // globe
    position: absolute;
    transform: translate3d(0, 0, 0);
    bottom: 50px;
    left: 40px;
    max-width: 170px;
    max-height: 230px;
  }

  /*
      SECTION 4
   */
  #section4_slide1_illustration1 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    bottom: 0px;
    left: 30px;
    max-width: 30%;
    max-height: 100%;

  }
  #section4_slide1_illustration2 {
    position: absolute;
    transform: translate3d(0, 0, 0) scale(0.8);
    top: 2%;
    right: 5px;
    animation-delay: .1s;
    max-width: 175px;
    max-height: 175px;
  }
  #section4_slide1_illustration3 {
    position: absolute;
    transform: translate3d(0, 0, 0) scale(0.8);
    bottom: 20px;
    right: 10px;
    max-width: 228px;
    max-height: 228px;
  }
  #section4_slide2_illustration1 {
    position: absolute;
    transform: translate3d(0, 0, 0) scale(0.8);
    bottom: -30px;
    right: 10px;
    animation-delay: .5s;

    max-width: 228px;
    max-height: 228px;
  }
  #section4_slide2_illustration2 {
    position: absolute;
    transform: translate3d(0, 0, 0) scale(0.8);
    top: 26px;
    right: 10px;
    animation-delay: .5s;

    width: 150px;
    height: 150px;
  }
  #section4_slide2_illustration3 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    bottom: 114px;
    left: 10px;
    max-width: 20%;
    max-height: 80%;
  }
  #section4_slide3_illustration1 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    bottom: 76px;
    left: 0;
    max-width: 20%;
    max-height: 80%;
  }
  #section4_slide3_illustration2 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    top: -50px;
    right: 20px;
    max-width: 20%;
    max-height: 80%;
  }

  /*
      SECTION 5
   */
  #section5_illustration1 { //light switch
    position: absolute;
    right: 2vw;
    bottom: 3vw;
    max-width: 120px;
    max-height: 120px;
    display: block;

  }
  #section5_illustration4 {
    position: absolute;
    right: 2vw;
    bottom: 3vw;
    max-width: 120px;
    max-height: 120px;
    display: none;
  }
  #section5_illustration2 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 154px;
    height: 462px;
    top: -50%;
    left: 1vw;
    opacity: 1;

    img {
      height:unset;
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      transition: opacity .2s ease-in;
      &.bulb_off{
        max-height: calc(100% - 20px);
      }
    }

    .bulb_on {
      opacity: 0;
    }
    .bulb_off {
      opacity: 1;
    }

  }
  /*
    SECTION 6
   */

  #section6_illustration1 {
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    top: 100%;
    left: 16%;
    max-width:239px;
    max-height:443px;
    z-index:50;
  }
  #section6_illustration2 {
    position: absolute;
    z-index:50;
    transform: translate3d(-50%, -50%, 0);
    left: 32%;
    top: 100%;
    max-width:239px;
    max-height:443px;
  }
  #section6_illustration3 {
    position: absolute;
    z-index:50;
    transform: translate3d(-50%, -50%, 0);
    top: 100%;
    left: 48%;
    max-width:239px;
    max-height:443px;
  }
  #section6_illustration4 {
    position: absolute;
    z-index:50;
    transform: translate3d(-50%, -50%, 0);
    top: 100%;
    left: 64%;
    max-width:239px;
    max-height:443px;
  }
  #section6_illustration5 {
    position: absolute;
    z-index:50;
    transform: translate3d(-50%, -50%, 0);
    top: 100%;
    left: 80%;
    max-width:239px;
    max-height:443px;
  }
  #section6_illustration6 {
    position: absolute;
    z-index:50;
    transform: translate3d(-50%, -50%, 0);
    top: 100%;
    left: 90%;
    max-width:239px;
    max-height:443px;
  }
  /*
    SECTION 7
   */
  #section7_illustration1 {
    position: absolute;
    z-index:10000;
    transform: translate3d(-50%,0, 0) rotateZ(-30deg) scale(0.8);
    top: 100%;
    left:-50%;
    max-width: 495px;
    max-height: 645px;
  }

  #section7_illustration2 { // horse
    position: absolute;
    transform: translate3d(-50%,0,0);
    top: 60px;
    left: 90%;

    max-width: 30%;
    max-height: 80%;
  }

  /*
    SECTION 8
   */

  #section8_illustration1 {
    position: absolute;
    transform: translate3d(0, 0, 0);
    right: 0;
    bottom: 0;
    pointer-events: none;

    max-width: 61%;
    max-height: 60%;
    overflow: hidden;
    z-index: 1000;
  }

  #score_illustration1 {
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    top: 50%;
  }

}

.section {
  position: relative;
  .fp-tableCell {
    overflow: hidden;
  }

  /*
      SECTION 2
   */
  #section2_illustration1 {
    //@include slideInFromRight(-50%, 0%, .5s, 1);
    @include animate(slide2_hand_with_phone, 2s, infinite, both);
    @include keyframes(slide2_hand_with_phone) {
      0%   {
        transform: translate3d(0, 0, 0) rotateZ(0deg);
        top: 100%;
      }
      100% {
        transform: translate3d(0, 0, 0) rotateZ(50deg);
        top: calc(100% - 200px);
      }
    }
  }

  #section2_illustration2 {
    @include animateTranslate(translate3d(0, 0, 0), translate3d(0, 120px, 0), 5s, infinite, both);
  }

  /*
      SECTION 3
   */
  #section3_illustration1 {
    @include popIn(translate3d(0, 0, 0), 0.4, 1, .8s);
  }

  /*
      SECTION 4
   */

  #section4_slide1_illustration2 {
    @include popIn(translate3d(0, 0, 0), 0.8, 1, 1.8s);
  }
  #section4_slide1_illustration3 {
    @include popIn(translate3d(0, 0, 0), 0.8, 1, 1.5s);
  }
  #section4_slide2_illustration1 {
    @include popIn(translate3d(0, 0, 0), 0.8, 1, 1.8s);
  }
  #section4_slide2_illustration2 {
    @include popIn(translate3d(0, 0, 0), 0.8, 1, 1.5s);
  }

  /*
      SECTION 5
   */

  #section5_illustration2 {
    @include slideUpAndDown(-50%, 0vh, .4s);
    .bulb_on {
      opacity: 0;
    }
    .bulb_off {
      opacity: 1;
    }

  }
  /*
    SECTION 6
   */

  #section6_illustration1 {
    @include animateTranslate(translate3d(-50%, 0, 0), translate3d(-50%, -150px, 0), 4s, infinite, both);
  }
  #section6_illustration2 {
    @include animateTranslate(translate3d(-50%, 0, 0), translate3d(-50%, -130px, 0), 2s, infinite, both);
    animation-delay: .2s;
  }
  #section6_illustration3 {
    @include animateTranslate(translate3d(-50%, 0, 0), translate3d(-50%, -100px, 0), 3s, infinite, both);
  }
  #section6_illustration4 {
    @include animateTranslate(translate3d(-50%, 0, 0), translate3d(-50%, -180px, 0), 4s, infinite, both);
    animation-delay: .2s
  }
  #section6_illustration5 {
    @include animateTranslate(translate3d(-50%, 0, 0), translate3d(-50%, -100px, 0), 2s, infinite, both);
    animation-delay: .2s
  }
  #section6_illustration6 {
    @include animateTranslate(translate3d(-50%, 0, 0), translate3d(-50%, -150px, 0), 3.5s, infinite, both);
    animation-delay: .3s
  }
  /*
    SECTION 7
   */
  #section7_illustration1{
    animation-name: wobblyDude;
    animation-duration: 20s;
    animation-iteration-count: infinite;

  }
  /*
    END
   */
  #score_illustration1 {
    @include popIn(translate3d(-50%, -50%, 0), 0.8, 1, .8s);
  }
}

.section.lightning {
  #section5_illustration4 {
    display: block;
  }
  #section5_illustration1 {
    display: none;
  }
  #section5_illustration2 {
    .bulb_on {
      opacity: 1;
    }
  }
}


@keyframes wobblyDude {
  // 0 hidden
  0%{
    transform: translate3d(-50%,0, 0) rotateZ(-30deg) scale(0.8);
    -ms-transform:  translate3d(-50%,0, 0) rotateZ(-30deg) scale(0.8, 0.8);
    top: 50vh;
    left:-50%;
  }
  // 1
  10%{
    transform: translate3d(-50%, -50%, 0) rotateZ(10deg) scale(0.8);
    -ms-transform:  translate3d(-50%,0, 0) rotateZ(10deg) scale(0.8, 0.8);
    top: 40%;
    left: 0;
  }
  // 1 pausing animation
  20%{
    transform: translate3d(-50%, -50%, 0) rotateZ(10deg) scale(0.8);
    -ms-transform:  translate3d(-50%, -50%, 0) rotateZ(10deg) scale(0.8, 0.8);
    top: 40%;
    left: 0;
  }
  //2 hidden
  30%{
    transform: translate3d(-50%, -50%, 0) rotateZ(0deg) scale(0.8);
    -ms-transform:  translate3d(-50%, -50%, 0) rotateZ(0deg) scale(0.8, 0.8);
    top: 100%;
    left:-50%;
  }
  //3 hidden
  31%{
    transform: translate3d(-50%, 0, 0);
    -ms-transform:  translate3d(-50%, 0, 0);
    top: 100%;
    left:-50%;
  }
  //4 hidden
  32%{
    transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    top: 100%;
    left: 50%;
  }
  //5 show right
  40%{
    transform: translate3d(-50%, -50%, 0) rotateZ(-30deg) scale(1.2);
    -ms-transform: translate3d(-50%, -50%, 0) rotateZ(-30deg) scale(1.2, 1.2);
    top: 100%;
    left: 50%;
  }
  // 5 pause animation
  50%{
    transform: translate3d(-50%, -50%, 0) rotateZ(-30deg) scale(1.2);
    -ms-transform: translate3d(-50%, -50%, 0) rotateZ(-30deg) scale(1.2, 1.2);
    top: 100%;
    left: 50%;
  }
  //6 hide
  60%{
    transform: translate3d(-50%, 50%, 0) rotateZ(-30deg) scale(1.2);
    -ms-transform: translate3d(-50%, 50%, 0) rotateZ(-30deg) scale(1.2, 1.2);
    top: 100%;
    left: 25%;
  }
  // 6 pausing animation
  70%{
    transform: translate3d(-50%, -50%, 0),  scale(1.5);
    -ms-transform: translate3d(-50%, -50%, 0),  scale(1.5, 1.5);
    top: 100%;
    left: 25%;
  }
  //7 show left
  80%{
    transform: translate3d(-50%, -50%, 0) rotateZ(5deg) scale(1.6);
    -ms-transform: translate3d(-50%, -50%, 0) rotateZ(5deg) scale(1.6, 1.6);
    top: 100%;
    left: 25%;
  } //7 pause left
  90%{
    transform: translate3d(-50%, -50%, 0) rotateZ(8deg) scale(1.6);
    -ms-transform: translate3d(-50%, -50%, 0) rotateZ(8deg) scale(1.6, 1.6);
    top: 100%;
    left: 25%;
  }
  //8 hide
  98%{
    transform: translate3d(-50%, 50%, 0) rotateZ(5deg) scale(1.5);
    -ms-transform: translate3d(-50%, 50%, 0) rotateZ(5deg) scale(1.5, 1.5);
    top: 100%;
    left: 25%;
  }
  // 9 hide
  99%{
    transform: translate3d(-50%, -50%, 0) scale(1);
    -ms-transform: translate3d(-50%, -50%, 0) scale(1, 1);
    top: 100%;
    left:-50%;
  }
  // 10 hide
  100%{
    transform: translate3d(-50%,0, 0) rotateZ(-30deg) scale(0.8);
    -ms-transform: translate3d(-50%,0, 0) rotateZ(-30deg) scale(0.8, 0.8);
    top: 50%;
    left:-50%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .section{
    #section7_illustration1{
      animation-name: none;
      transform: translate3d(-50%, -50%, 0) scale(1.5);
      top: 100%;
      left: 20%;
    }
  }
  @keyframes wobblyDude {}
}
