@media only screen and (max-width: 800px) and (orientation: portrait) {


  .warning {
    display: block;
    position: absolute;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background-color: #32373C;

    .rotate_screen_message {
      display: block;
    }
    .mobile_unavailable_message {
      display: none
    }
    .background-stars, .background-stars--portrait {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      transform: unset;
    }
    .background-stars--slow {
      animation-duration: 200s;
    }

  }

  .header {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hint {
    .content {
      max-width: 75vw;
    }
  }
  .warning {
    display: block;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background-color: #32373C;
    position: absolute;

    .rotate_screen_message {
      display: block;
    }
    .mobile_unavailable_message {
      display: none
    }

    .background-stars, .background-stars--portrait {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      transform: unset;
    }

    .background-stars--slow {
      animation-duration: 200s;
    }

  }

  .header {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (max-height: 1023px) {
  .warning {
    .rotate_screen_message {
      display: none;
    }
    .mobile_unavailable_message {
      display: block;
      width: 80%;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: unset;
      top: unset;
      left: unset;
    }
  }
}

@media only screen and (orientation: portrait) {
  .warning {
    .mobile_unavailable_message {
      width: 90%;
    }

    .background-stars {
      display: none;
    }
    .background-stars--portrait{
      display: block;
      height: 100%;
      width: auto;
    }
  }
}

@media only screen and (orientation: landscape) {
  .warning {
    .mobile_unavailable_message {
      height: 90%;
    }


    .background-stars--portrait{
      display: none;
    }
    .background-stars {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 1600px){
  .section .question__wrapper p {
    font-size: 3.7rem;
  }
  .section.intro .header-text h1{
    font-size: 3.7rem;
  }
}