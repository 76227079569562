h1{
  letter-spacing: 1px;
}
h2{
  letter-spacing: 0.9px;
  line-height: 2.7rem;

  &.bold{
    font-family: $font-bold;
    font-weight: bold;
  }
}